import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, frFR } from "@material-ui/data-grid";
import { Add } from "@material-ui/icons";
import { Colors } from "../../theme";
import {
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useQuery } from "@apollo/client";
import { USER_LISTING } from "../../graphql";
import { ROUTES } from "../../constants";
import styles from "./UsersListingStyles";
import { Header } from "../../components";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  cardWrapper: {
    boxShadow: "1px 1px 9px 2px #0000001a",
    borderRadius: 7,
  },
  cardContentWrapper: {
    padding: "16px 26px",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 600,
    height: 45,
    minWidth: 45,
    borderRadius: "100%",
    boxShadow: "2px 2px 8px 1px #0000002b",
    marginLeft: "auto",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  title: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
}));

export default function UsersListingView(props) {
  const user = useSelector((state) => {
    return state.user;
  });
  const history = useHistory();
  const classes = useStyles();

  const [tableData, setTableData] = useState(() => []);
  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
  };

  const {
    data,
    loading: loadingUsers,
    error: errFetchUsers,
  } = useQuery(USER_LISTING(user.youth_center.id), {
    onCompleted: (data) => {
      const users = data.usersPermissionsUsers.data.map((user) => {
        return {
          id: user.id,
          firstName: user.attributes.firstName,
          lastName: user.attributes.lastName,
          email: user.attributes.email,
        };
      });

      setTableData(users);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let hasError = true;

    if (errFetchUsers) {
      setClientErrMsg("Erreur lors du chargement des usagers");
    } else {
      hasError = false;
    }

    if (hasError) {
      setOpenErrSnackBar(true);
    }
  }, [errFetchUsers]);

  const columns = [
    {
      key: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
    },
    {
      key: "name",
      field: "name",
      headerName: "Nom",
      sortable: false,
      minWidth: 200,
      renderCell: (param) => {
        return (
          <div>
            {`${param.row?.firstName || ""} ${param.row?.lastName || ""}`}
            {param.row?.blocked === true && " (désactivé)"}
          </div>
        );
      },
    },
    {
      key: "email",
      field: "email",
      headerName: "Courriel",
      sortable: false,
      minWidth: 300,
      renderCell: (param) => {
        return <div>{`${param.row?.email}`}</div>;
      },
    },
  ];

  const addUser = () => {
    history.push(`${ROUTES.NEW_USER}`);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <Header />
      <>
        <div className={classes.root}>
          <Container maxWidth="lg" className={css(AppStyles.marginTop84)}>
            <Card className={classes.cardWrapper}>
              <CardContent className={classes.cardContentWrapper}>
                <Grid
                  style={{ width: "100%" }}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={6} md={11}>
                    <Typography variant="h4" className={classes.titleone}>
                      Usagers
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={1} alignItems="flex-end">
                    <Button onClick={addUser} className={classes.addBtn}>
                      <Add />
                    </Button>
                  </Grid>
                </Grid>
                <div className={css(styles.TableWrpper)}>
                  {loadingUsers ? (
                    <div className="progress-container">
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <DataGrid
                      className={`usersTable`}
                      localeText={frFR.props.MuiDataGrid.localeText}
                      rows={tableData}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 50, 100]}
                      isRowSelectable={false}
                      disableSelectionOnClick={true}
                      disableColumnMenu={true}
                      sortingOrder={["desc", "asc", null]}
                      onRowClick={(param) => {
                        history.push(`${ROUTES.USERS}/${param.row.id}`);
                      }}
                    />
                  )}
                </div>
              </CardContent>
            </Card>
          </Container>
        </div>
      </>
    </div>
  );
}
