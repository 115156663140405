import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Card,
  CardContent,
  Container,
  Paper,
  Typography,
} from "@material-ui/core";
import Table from "./components/Table";
import { AppStyles } from "../../theme";
import { css } from "aphrodite";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES, USER_PERMISSIONS } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
  },
  appBarBg: {
    background: "#5cbdaf",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  cardWrapper: {
    boxShadow: "1px 1px 9px 2px #0000001a",
    borderRadius: 7,
  },
  cardContentWrapper: {
    padding: "16px 26px",
  },
}));

export default function DashboardView() {
  const match = useMediaQuery("(max-width:600px)");
  const user = useSelector((state) => {
    return state.user;
  });
  const history = useHistory();

  React.useEffect(() => {
    if (user.role.name === USER_PERMISSIONS.RMJQ) {
      history.push(ROUTES.ACTIVITIES);
    }
  }, [user]);

  const classes = useStyles();

  return (
    <div>
      <Header title="Journal de bord" />
      <>
        <div className={classes.root}>
          <Container
            maxWidth="lg"
            className={
              match ? css(AppStyles.marginTop84) : css(AppStyles.marginTop84)
            }
          >
            <Card className={classes.cardWrapper}>
              <CardContent className={classes.cardContentWrapper}>
                <Typography variant="h4" className={classes.title}>
                  Journal de bord
                </Typography>
                <Table />
              </CardContent>
            </Card>
          </Container>
        </div>
      </>
    </div>
  );
}
